import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { GlobalController } from '../controllers/global.controller'
import { AlertProvider, HoursWorkedEntry } from '../providers/alerts.provider'
import { AuthProvider } from '../providers/auth.provider'
import { CommunicationProvider } from '../providers/communication.provider'

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  communicationsAlerts: number = 0
  generalAlerts: number = 0
  assignationAlerts: number = 0
  sitesAlerts: number = 0
  hourAlerts: number = 0

  constructor(
    private authProvider: AuthProvider,
    private router: Router,
    public globalCtrl: GlobalController,
    private communicationProvider: CommunicationProvider,
    private alertProvider: AlertProvider
  ) {}

  getNotifications(type = null) {
    if (type == 'Assignation') {
      return this.assignationAlerts
    } else if (type == 'General') {
      return this.generalAlerts
    } else if (type == 'AllMessages') {
      return this.communicationsAlerts
    } else if (type == 'SitesAlerts') {
      return this.sitesAlerts
    } else if (type == 'HourAlerts') {
      return this.hourAlerts
    }
  }

  setMessagesNotifications(alerts, type) {
    type == 'General' ? (this.generalAlerts = alerts) : (this.assignationAlerts = alerts)
    this.communicationsAlerts = this.generalAlerts + this.assignationAlerts
  }

  updateMessageAlerts() {
    this.communicationProvider.getAllUnseenCommunications('Assignation').then((alerts: any) => {
      this.assignationAlerts = alerts
    })
    this.communicationProvider.getAllUnseenCommunications('General').then((alerts: any) => {
      this.generalAlerts = alerts
    })
    this.communicationsAlerts = this.generalAlerts + this.assignationAlerts
  }

  updateHourAlerts() {
    this.alertProvider.getHoursWorked().then((res: HoursWorkedEntry[]) => {
      this.hourAlerts = res.length
    })
  }

  updateAlerts(days: number) {
    this.alertProvider.getAlertsNotifications(days).then((res: any) => {
      this.sitesAlerts = res.numberOfAlerts
    })
  }
}
