import { Component, OnInit } from '@angular/core'
import releases from '../release_logs.json'
import { AuthProvider } from './providers/auth.provider'

import { MenuController, ModalController } from '@ionic/angular'
import { PrimeNGConfig } from 'primeng/api'
import { DeviceController } from './controllers/device.controller'
import { GlobalController } from './controllers/global.controller'

import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { ChangelogComponent } from './components/changelog/changelog.component'
import { LogoutService } from './services/logout.service'
import { NotificationsService } from './services/notifications.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = []
  public dashboards = []
  showSideMenu = false
  userRole = 'employee'
  releases = []
  sideMenuEnabled = false

  constructor(
    private router: Router,
    public authProvider: AuthProvider,
    private deviceCtrl: DeviceController,
    public globalCtrl: GlobalController,
    public logoutService: LogoutService,
    private modalCtrl: ModalController,
    private PrimeNGConfig: PrimeNGConfig,
    public notificationsService: NotificationsService,
    private menuController: MenuController
  ) {
    this.globalCtrl.events.subscribe('user:login', () => {
      this.showSideMenu = true
      this.sideMenuEnabled = true
      this.userRole = this.authProvider.getUserRole()
      return false
    })
    this.globalCtrl.events.subscribe('user:logout', () => {
      this.showSideMenu = false
      this.sideMenuEnabled = false
    })
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.authProvider.getUserRole() === 'admin') {
        this.notificationsService.updateMessageAlerts()
        this.notificationsService.updateHourAlerts()
        this.notificationsService.updateAlerts(7)
      }
    })
  }

  ngOnInit() {
    this.releases = releases

    this.appPages = [
      {
        title: 'Overview',
        url: '/overview',
        icon: 'podium',
        data_cy: 'overviewPage',
      },
      {
        title: 'Assignations',
        url: '/matching',
        icon: 'link',
        data_cy: 'matchingPage',
      },
      {
        title: 'Commandes',
        url: '/orders',
        icon: 'file-tray-full',
        data_cy: 'orderPage',
      },
      {
        title: 'Clients',
        url: '/clients',
        icon: 'happy',
        data_cy: 'clientsPage',
      },
      {
        title: 'Superviseurs',
        url: '/supervisor',
        icon: 'megaphone',
        data_cy: 'supervisorPage',
      },
      {
        title: 'Employés',
        url: '/employees',
        icon: 'people',
        data_cy: 'employeesPage',
      },
      {
        parent: {
          title: 'Communications',
          url: '/messages',
          icon: 'mail',
          data_cy: 'messagesPage',
          alerts: true,
        },
        children: [
          { title: 'General', url: '/messages/general', alerts: true },
          { title: 'Assignation', url: '/messages/assignation', alerts: true },
        ],
      },
      {
        title: 'Feuilles de temps',
        url: '/timesheets',
        icon: 'time',
        data_cy: 'timesheetPage',
      },
      { title: 'Outils', url: '/tools', icon: 'hammer', data_cy: 'ToolsPage' },
      {
        title: 'Compétences',
        url: '/skills',
        icon: 'school',
        data_cy: 'skillsPage',
      },
    ]

    this.dashboards = [
      {
        title: 'Employés dispo.',
        url: '/dashboard-employees',
        icon: 'people',
      },
      { title: 'Clients Actuels', url: '/clients-dashboard', icon: 'happy' },
      { title: 'Localisations', url: '/dashboard/map', icon: 'map' },
      { title: 'Absences', url: '/absences', icon: 'calendar' },
      { title: 'Alertes', url: '/alerts', icon: 'alert-circle', alerts: true },
      { title: 'Alertes Heures', url: '/hour-alerts', icon: 'time', hourAlerts: true },
    ]

    // no need to configure luxon day of the week start, it is already set to monday

    this.PrimeNGConfig.setTranslation({
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    })

    this.showSideMenu = true
  }

  showSplitPane() {
    if (this.authProvider.getUserRole() !== 'admin') {
      this.sideMenuEnabled = false
      return false
    }
    this.sideMenuEnabled = true

    return this.deviceCtrl.desktopView && this.showSideMenu
  }

  async openChangesModal() {
    const showReleaseModal = await this.modalCtrl.create({
      component: ChangelogComponent,
      cssClass: 'desktopReleaseLogsModal',
      componentProps: {
        releases: this.releases,
      },
    })

    await showReleaseModal.present()
  }

  accordionClicked(event: Event) {
    event.stopPropagation()
  }

  async childItemClicked(event: Event) {
    await this.menuController.close()
  }

  hasAlerts(page): boolean {
    return (page?.alerts && this.notificationsService.getNotifications('SitesAlerts') > 0) ||
           (page?.hourAlerts && this.notificationsService.getNotifications('HourAlerts') > 0)
  }

  notificationCount(page): number {
    if (!this.hasAlerts(page)) return 0

    const alertType = page?.alerts ? 'SitesAlerts' : page?.hourAlerts ? 'HourAlerts' : null
    return alertType ? this.notificationsService.getNotifications(alertType) : 0
  }
}
